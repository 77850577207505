<template>
  <sm-editable-list
    :controllerName="controllerName"
    :parentName="parentName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  >
    <template v-slot:logFile="{ row }">
      <a
        v-if="row.logFile"
        class="editable-list__link"
        @click="onDownloadFile(row.id)"
      >
        {{ row.logFile.fileName }}
      </a>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'CustomPeriodicTasksLogForCustomPeriodicTasks',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'CustomPeriodicTasksLog',
      parentName: 'Task',
      tableCaption: 'Кастомные периодические задачи - логи',
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'Название задачи', alias: 'taskName', order: 'taskName' },
        { text: 'Время запуска', alias: 'startTime', order: 'startTime' },
        { text: 'Время окончания', alias: 'finishTime', order: 'finishTime' },
        { text: 'Успешно или нет', alias: 'isSuccess', order: 'isSuccess' },
        {
          text: 'Файл с логом',
          alias: 'logFile',
          order: 'logFile',
        },
      ],
    };
  },

  computed: {
    id() {
      return +this.$route.params.id;
    },

    breadcrumbs() {
      return [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Кастомные периодические задачи',
          route: { name: 'CustomPeriodicTasks' },
        },
        {
          text: `Редактирование кастомной периодической задачи ${this.id}`,
          route: { name: 'CustomPeriodicTaskEdit', params: { id: this.id } },
        },
        {
          text: 'Кастомные периодические задачи - логи',
        },
      ];
    },
  },

  methods: {
    ...mapActions(['downloadFile']),

    onDownloadFile(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },
  },
};
</script>
